@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-black font-Montserrat !scrollbar-thin !scrollbar-track-transparent 
    !scrollbar-thumb-[#1C394B];
  }
}

@layer components {
  .customIcon {
    @apply h-6 w-6 hover:text-[#C50404] transition duration-500 ease-in-out text-blue;
  }

  .customButton {
    @apply capitalize py-4 px-6 sm:px-10 shadow-neutral-900 inline-flex items-center justify-center 
    text-center text-black hover:shadow-lg font-medium rounded-lg transition duration-300 ease-in-out;
  }

  .card {
    @apply relative w-72 h-44 cursor-pointer border-b-4 rounded-md text-center px-3 py-7 duration-500 delay-100;
  }

  .card-active {
    @apply h-96;
  }

  .logo {
    @apply w-72 h-72 mt-2 mx-auto grid place-items-center rounded-lg;
  }

  .para {
    @apply mt-3;
  }

  .productBtn {
    @apply capitalize py-2 px-4 drop-shadow-md inline-flex items-center justify-center text-center text-black shadow-lg font-medium rounded-lg transition duration-300 ease-in-out;
  }
}
.homeHeroBackground {
  position: absolute;
  opacity: 0.15;
  z-index: -1;
}
.serviceCarousel,
.products,
.testimonials,
.homeHero {
  height: calc(100vh - 100px);
}
.triangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 10px solid transparent;
}
.aboutBg {
  background-image: url("https://res.cloudinary.com/dqj5beowr/image/upload/v1680409953/Clothing/unsplash_vzheha.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
nav a.active {
  color: #c50303;
}
.oemDropped,
.productsDropped,
.moreDropped {
  display: none;
}
.oemDropDown:hover .oemDropped {
  display: block;
}
.productsDropDown:hover .productsDropped {
  display: flex;
}
.moreDropDown:hover .moreDropped {
  display: flex;
}
.navBarHide {
  display: none;
}
.BlogContent > h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.BlogContent > h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.BlogContent > h3 {
  font-size: 18.72px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.BlogContent > h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1rem;
}